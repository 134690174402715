import React from 'react'
import { Link } from 'gatsby'
import Layout from "../../components/Layout"

export default class CMEPage extends React.Component {
  render() {
    return (  
      <div>
        <Layout>
          <div className="aga-container aga-fade-in">
            <h1>CME Credit</h1>
            <h3>Earn 3.00 <em>AMA PRA Category 1 CME</em> Credits</h3>
            <p>Review the three case studies &mdash; <Link to="/cases/primary-care-diagnosis">Diagnosis</Link>, <Link to="/cases/medication-management-gastroenterology">Medication Management</Link> and <Link to="/cases/health-maintenance-primary-care">Health Maintenance</Link> &mdash; and listen to the accompanying <Link to="/media">podcasts</Link> and complete the exam with a passing grade to earn 3.00 <em>AMA PRA Category 1 credits</em>.</p>
            <p>Learning Objectives:</p>
            <ul>
              <li>Recognize when patient referral to a gastroenterologist from primary care is appropriate and timely</li>
              <li>Describe safety and efficacy of current and emerging medications for ulcerative colitis and Crohn’s disease</li>
              <li>Describe strategies for modifying treatment regimens for symptom relief and to achieve remission</li>
            </ul>

            <p>To complete the posttest and claim your credits, please click the link below for the exam. On the following page, you will need to log into your AGA account to complete the activity. If you do not have an AGA account, you will be able to create one.</p>

            <p>Continue to Better IBD Care posttest and credit collection: <br />
            <a href="http://agau.gastro.org/diweb/mylearning" target="_blank" rel="noopener noreferrer">http://agau.gastro.org/diweb/mylearning</a>
            </p>
            
          </div>
        </Layout>
      </div>
    )
  }
}